import { ref, reactive } from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useFinancialTransactions() {

    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]); //ref: to make it reactive
    const financial_transactions = ref([]); //ref: to make it reactive
    const financial_transaction = ref([]); //ref: to make it reactive
    const lists = reactive({});

    const getFinancialTransactions = async (data = {}) => {
        try {
            let response = await axios.post('/api/financial_transactions/get', data);
            financial_transactions.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getFinancialTransaction = async (id) => {
        try {
            let response = await axios.get('/api/financial_transactions/' + id);
            financial_transaction.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeFinancialTransaction = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/financial_transactions/', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const getListsForFinancialTransactions = async () => {
        try {
            let response = await axios.post('/api/financial_transactions/lists');
            lists.headings = response.data.data.headings;
            lists.accounts = response.data.data.accounts;
            lists.types = response.data.data.types;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const deleteFinancialTransaction = async (id) => {
        try {
            await axios.delete('/api/financial_transactions/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const updateFinancialTransaction = async (data, id) => {
        errors.value = ''; //
        try {
            data.append("_method", 'PATCH');
            let response = await axios.post('/api/financial_transactions/' + id, data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    return {
        financial_transactions,
        financial_transaction,
        storeFinancialTransaction,
        getFinancialTransactions,
        getFinancialTransaction,
        deleteFinancialTransaction,
        getListsForFinancialTransactions,
        updateFinancialTransaction,
        lists,
        msgs,
        errors,
    }
}